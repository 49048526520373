.box {
    border-radius: 6px;
    border-color: #0000001f;
    border-width: 1px;
    padding: 0px;
}

.informationDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
}

.labelP {
    font-weight: 500;
}

.valueP {
    font-weight: 250;
}

.productLineDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productLineP {
    vertical-align: center;
    text-align: left;
    font-weight: 300;
}

.productLineHeaderP {
    vertical-align: center;
    text-align: left;
    font-weight: 500;
}