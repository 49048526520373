.nonSelected {
  background-color: #fff !important;
  color: #4d5e80 !important;
  margin-top: 10px !important;
  max-width: 40px !important;
}

.selected {
  background-color: #fff !important;
  color: #189DD9 !important;
  max-width: 40px !important;
  margin-top: 10px !important;
}

.nonSelectedMobile {
  background-color: #fff !important;
  color: #4d5e80 !important;
  margin-top: 1px !important;
  margin-bottom: 5px !important;
  max-width: 40px !important;
}

.selectedMobile {
  background-color: #fff !important;
  color: #189DD9 !important;
  max-width: 40px !important;
  margin-top: 1px !important;
  margin-bottom: 5px !important;
}

.selectedTextMobile {
  color: #189DD9 !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.nonSelectedTextMobile {
  color: #000 !important;
  margin-top: 0px !important;
  margin-left: 0px !important;
}

.selectedText {
  color: #189DD9 !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}

.nonSelectedText {
  color: #000 !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}


.slider {
  display: flex !important;
  flex-direction: column !important;
  height: calc(100vh - 85px);
  margin-right: 10px !important;
  justify-content: space-between !important;
}

.sliderMobile {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  background-color: #F7F8FA;
  border-top: 1px solid #4444;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.selectedTextSubMenu {
  padding: 5px 20px 20px 20px;
  margin: 10px 0px 0px 0px;
  background-color: #f7f8fa !important;
  box-shadow: 14px 0px 10px 1px rgb(0 0 0 / 35%);
}

.nonSelectedTextSubMenu {
  text-decoration: none;
  padding-left: 13px;
  padding-left: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: -7px;
  width: 100%;
  color: #000 !important;
  background-color: #4442;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 15px;
  margin-bottom: 2px;

}