.App {
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url('../../public/bck.png');
  object-fit: contain;
  background-size: cover;
}

.App-Column {
  flex-direction: column;
}

.loginContainer {
  width: 1200px;
  height: 700px;
  opacity: 0.96;
  background-color: transparent;
  @media (max-width: 1250px) {
    width: 900px;
    height: 600px;
  }
  @media (max-width: 900px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 600px) {
    width: 350px;
    height: 500px;
  }
}


.img{
  width:100%;
}

.gridMainContainer{
  height: 100% ;

}

.firstGrid{
  height: 100%;
  display: flex;
  flex-direction:  column !important;
  align-items: center;
  justify-content: center;
  width: 100%;

}

.imageContainer{
  display: flex;
  align-items: center;
  padding-right:45px;
  @media (max-width: 900px) {
    display: none;
    }
}

.textfield {
  margin-bottom: 20px !important;
}

.form {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 65%;
}

.submit {
  padding: 10px !important;
  margin-top: 40px !important;
  color: #fff !important;
}

.errorText {
  font-size: 17px !important;
  margin: 15px !important;
  color: red !important;
}