.dropzoneArea {
    margin: 5px 15px 15px 15px;
    max-height: 150px;
    overflow-y: auto;
    width: 95% !important;
}

.showImagesBtn {
    color: white !important;
    margin: 15px !important
}

.deleteImagesBtn {
    position: absolute !important;
    right: -10px !important;
    top: -20px !important;
}

.checkboxImagesBtn {
    position: absolute !important;
    left: -10px !important;
    top: -20px !important;
}

.displayImagesContainer {
    max-height: 680px !important;
    display: flex !important;
    overflow-x: auto !important;
}

.displayImages {
    height: 200px !important;
    margin: 15px 15px !important;
    position: relative !important;
}

.displayImagesSmall {
    height: 100px !important;
    margin: 15px 15px !important;
    position: relative !important;
}