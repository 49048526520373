.statisticallyText {
    padding: 15px;
    font-size: 22px;
    text-align: center;
    margin: 10px 0px;
}

.statisticallyText span {
    font-weight: 100;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 9pt;
    display: block;
}

.reportsHeader {
    background-color: #404a694d !important;
    padding: 10px !important;
    border-radius: 0px !important;
    border-top-left-radius: 5px 5px !important;
    border-top-right-radius: 5px 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    font-size: 18px !important;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.reportsFooter {
    background-color: #189DD9 !important;
    color: white !important;
    padding: 10px !important;
    border-radius: 0px !important;
    border-bottom-left-radius: 5px 5px !important;
    border-bottom-right-radius: 5px 5px !important;
    display: flex !important;
    justify-content: space-between !important;
    font-size: 18px !important;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.reportsPaperWithTotals {
    border-radius: 0px !important;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.reportsTotalsGridItems {
    border-right: 1px solid #f3f3f3 !important;
    padding: 15px 0px !important;
}

.reportsTotalsItemContainer {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
}

.reportsTotalsItemLabel {
    font-weight: 200 !important;
    border-bottom: 1px solid #f3f3f3 !important;
    padding-bottom: 10px !important;
    margin-bottom: 10px !important;
    display: inline-block !important;
}

.reportsTotalsItemValue {
    font-weight: 600 !important;
}