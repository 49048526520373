.dropzoneArea {
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background-color: #F9F9F9;
    border-color: #D8D8D8;
    border-width: 2px;
    border-radius: 5px;
}

.imageStyle {
    height: 150px !important;
    width: 100px !important;
    object-fit: cover !important;
    margin: 5px !important;

}

.imageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.emptyPdfContainer {
    height: 150px !important;
    width: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #bcbcbc;
    margin: 5px !important;
}

.genericDocIcon {
    height: 150px !important;
    width: 100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 5px !important;
    border-radius: 6px;
}