.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 270px + var(--isSmall) * 155px);
  align-items: flex-start;
  padding: 100px 25px 25px 25px;
  margin-left: calc(220px + var(--isSmall) * -155px);
}


.settingsHeader {
  margin-top: 72px;
  left: calc(221px + var(--isSmall) * -155px) !important;
  border-radius: 0px;
  padding: 10px;
  box-shadow: none;
}

.settingsHeaderMobile {
  margin-top: 72px;
  left: 0px !important;
  border-radius: 0px;
  padding: 10px;
  box-shadow: none;
}

.mainContainerMobile {
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: flex-start;
  padding: 100px 20px 110px 20px;
}

.mainContainerUser {
  width: 100%;
}

.mainContainerOrder {
  display: flex;
  flex-direction: column;
  background-color: #F7F8FA;
  width: 100%;
  align-items: flex-start;
  padding: 100px 25px 25px 25px;
  margin-left: calc(220px + var(--isSmall) * -155px);
}

.mainContainerOrderMobile {
  display: flex;
  flex-direction: column;
  background-color: #F7F8FA;
  width: auto;
  align-items: flex-start;
  padding: 100px 25px 25px 25px;
}

.orderHeader {
  position: fixed;
  top: 70px;
  right: 0px;
  left: calc(240px + var(--isSmall) * -134px);
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  z-index: 1;
}

.orderHeaderMobile {
  position: fixed;
  top: 70px;
  right: 0px;
  left: 0px;
  background-color: #f7f8fa;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
  z-index: 1;
}

.mainContainerReports {
  width: 100%;
  padding: 100px 25px 25px 25px;
  margin-left: calc(220px + var(--isSmall) * -155px);
}

.mainContainerReportsMobile {
  width: auto;
  padding: 100px 25px 25px 25px;
}

.mainContainerDashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding: 100px 25px 70px 25px;
  margin-left: calc(220px + var(--isSmall) * -155px);
}


.newTaskButton {
  border-radius: 50px !important;
}

.papersContainer {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  width: 100%;
}

.taskPaper {
  width: 75%;
  padding: 20px;
  background-color: #ffffff;
  opacity: 0.8;
  background-image: radial-gradient(#000000 0.75px, #ffffff 0.75px);
  background-size: 15px 15px;
}


.addButton {
  margin-right: 10px !important;
}

.addButtonTableHeader {
  margin-right: 10 !important;
  background-color: #FFF !important;
  color: #000 !important;
  border-radius: 50px !important;
}

.addButtonTableHeader :hover {
  background-color: #fff !important;
  color: #189DD9 !important;
}


.sliderContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  width: 280px !important;
  justify-content: space-between;
  margin-right: 20px;
  padding: 20px;
  min-height: calc(100vh - 140px);

  @media (max-width: 767px) {
    background-color: #F7F8FA;
    position: absolute;
    right: 0;
    border-radius: 0px 0px 0px 10px
  }
}


.tableContainer {
  width: 100%;
}

.tableContainerMobile {
  width: 100%;
}


.filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px !important;
}

.autocomplete {
  width: 100%;
}