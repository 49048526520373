.container {
  display: flex;
  padding: 5px;
  justify-content: space-between;
  background-color: "#ff5";

  @media (max-width: 767px) {
    display: flex;
    padding: 5;
    justify-content: space-between;
    background-color: "#ff5";
  }
}

.title {
  padding-top: 7px !important;
  padding-left: 15px !important;
  font-size: large;

  @media (max-width: 767px) {
    display: none;
  }
}

.fab {
  background-color: #FFF !important;
  margin-right: 10px !important;
  color: #4D5E80 !important;
}

.fab:last-child {
  margin-right: 0px !important;
}

.secondContainer {
  display: flex;
  margin-right: 10px;
}

.username {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;

  @media (max-width: 767px) {
    display: none;

  }
}

.searchField {
  display: flex;
  background-color: #fff;
  border-radius: 50px;
  height: 35px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 5px;

  @media (max-width: 767px) {
    display: flex;
    border-radius: 50px;
    height: 35px;
    margin-left: 20px;
    margin-right: 20px;
    background-color: #fff;

  }
}