.mainHeader {
  width: 100%;
  padding: 10px;
  position: fixed;
  background-color: #189DD9;
  border-bottom: 1px solid #4444;
  z-index: 2;
}

.mainContainer {
}


.userIcon {
  position: fixed !important;
  bottom: 10px;
  left: 10px;
}