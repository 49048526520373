html {
  --isSmall: 0;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.css-15bah7x-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background-color: #189DD9;
  color: #fff !important;
  font-weight: 800;
  border-radius: 10 !important;
}

.MuiTabs-scroller {
  overflow: auto !important;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  background: #ffffff2b;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 1500;
  align-items: center;
  justify-content: center;
}

.loadingDisabled {
  display: none;
}

.mainSliderLarge {
  background-color: #F7F8FA;
  border-right: 1px solid #4444;
  width: 220px;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
}

.mainSliderSmall {
  background-color: #F7F8FA;
  border-right: 1px solid #4444;
  max-width: 65px !important;
  position: fixed;
  top: 70px;
  left: 0;
  bottom: 0;
}

.mainSliderMobile {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 50px;
  z-index: 1;
  background-color: #F7F8FA;
  border-right: 1px solid #4444;
}

.mainSliderMobileOpen {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: 133px;
  z-index: 1;
  background-color: #F7F8FA;
  border-right: 1px solid #4444;
}

.containerLarge {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: auto !important;
  position: relative !important;
  max-width: 210px !important;
}

.containerSmall {
  display: flex !important;
  padding: 10px !important;
  justify-content: space-between !important;
  overflow: hidden !important;
  position: relative !important;
  max-width: 50px !important;
}



input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

*:focus {
  outline: none;
}



.rdw-image-modal {
  left: auto !important;
  right: 0 !important;
}

.rdw-emoji-modal {
  left: auto !important;
  right: 0 !important;
}

.rdw-embedded-modal {
  left: auto !important;
  right: 0 !important;

}

.MuiStepConnector-line {
  height: 100% !important;
}